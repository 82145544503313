import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import React, { Fragment, useState } from "react";

const Job3 = () => {
  return (
    <Fragment>
      <Header2 />

      <section style={{ marginTop: "150px", marginBottom: "75px" }}>
        <div className="container" style={{ marginTop: "50px" }}>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="col-lg-9"
              style={{
                width: "99vw",
                marginLeft: "12px",
                marginRight: "12px",
                border: "1px solid black",
                paddingLeft: "3vw",
                paddingRight: "3vw",
                paddingTop: "50px",
                paddingBottom: "75px",
              }}
            >
              {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
              <div>
                <p>
                  <span
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    Position :
                  </span>{" "}
                  Head Marketing
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Qualification :
                  </span>{" "}
                  Post Graduation in Marketing
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Experience :
                  </span>{" "}
                  15+ Years
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Work Location :
                  </span>{" "}
                  Hyderabad
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    About the Role:
                  </span>{" "}
                  <br />
                  As the Head of Marketing, you will spearhead strategic
                  marketing initiatives to enhance product visibility, drive
                  sales growth, and establish products as market-leading brands.
                  This position requires close collaboration with sales teams,
                  designers, and other departments to implement effective
                  marketing strategies and achieve measurable business outcomes.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Key Responsibilities:
                  </span>{" "}
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    1.Strategic Marketing Development
                  </span>{" "}
                  <br />
                  Formulate and execute comprehensive marketing strategies to
                  boost sales, build strong brands, and expand market share.
                  <br />
                  Utilize market surveys and IPM (Indian Pharmaceutical Market)
                  data to identify growth opportunities in specialty areas.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    2.Product Campaigns
                  </span>{" "}
                  <br />
                  Design and manage impactful product campaigns, such as RBG
                  (Red Blood Group) camps and OPD (Outpatient Department) camps,
                  to establish and strengthen brand presence.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    3.Sample Planning and Disbursement
                  </span>{" "}
                  <br />
                  Develop and oversee strategies for product sample planning and
                  disbursement to effectively enhance sales performance.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    4.Input Planning and Coordination
                  </span>{" "}
                  <br />
                  Plan and coordinate the procurement of marketing inputs, such
                  as gifts and compliments, ensuring timely availability through
                  inter-departmental collaboration.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    5.Customer Data Management
                  </span>{" "}
                  <br />
                  Compile and maintain an updated database of target doctors and
                  customers in the required format to align with marketing
                  objectives.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    6.Target Allocation and Bifurcation
                  </span>{" "}
                  <br />
                  Establish and allocate brand-specific sales targets unit-wise
                  and headquarter-wise for the upcoming financial year (FY
                  2025–26).
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    7.Monitoring and Metrics
                  </span>{" "}
                  <br />
                  Implement monitoring systems to track the utilization and
                  effectiveness of inputs and brand performance.
                  <br />
                  Analyze Input vs. ROI data and follow up to optimize resource
                  allocation and improve outcomes.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    8.Promotional Material Development
                  </span>{" "}
                  <br />
                  Develop creative concepts and collaborate with designers to
                  produce high-quality promotional materials, such as LBLs
                  (Leave Behind Literature).
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    9.Sales Team Collaboration
                  </span>{" "}
                  <br />
                  Work closely with sales teams to ensure the effective
                  execution of marketing initiatives and campaigns in the field.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    10.Brand Launch and Accuracy
                  </span>{" "}
                  <br />
                  Apply scientific and strategic methodologies to create,
                  position, and successfully launch new brands in the market.
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    11.Market Insights and Specialty Growth
                  </span>{" "}
                  <br />
                  Conduct comprehensive market surveys to identify and
                  capitalize on specialty growth opportunities, contributing to
                  accurate forecasting and strategic positioning.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Key Performance Indicators:
                  </span>{" "}
                  <br />
                  1.Growth in market share and sales revenue.
                  <br /> 2.Successful execution of product campaigns and
                  maximization of ROI on promotional inputs. <br />
                  3.Timely and effective implementation of marketing strategies
                  and product launches. <br />
                  4.Accurate tracking and reporting of input utilization and
                  brand performance metrics. <br />
                  5.Maintenance and expansion of a high-quality customer
                  database.
                </p>
                {/* <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Who We're Looking For:
                  </span>{" "}
                  <br />
                  Qualification: MSc in Microbiology (or equivalent).
                  <br />
                  Experience: 2-4 years of hands-on experience in
                  microbiological testing and analysis. Strong understanding of
                  microbiological techniques and laboratory safety protocols.
                  Detail-oriented with excellent organizational and
                  problem-solving skills. Proficiency in using laboratory
                  equipment and software for data management.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Why Join Us?
                  </span>{" "}
                  <br />
                  Opportunity to work in a dynamic and growth-oriented
                  environment.
                  <br />
                  Competitive compensation and benefits package. <br />
                  Be part of a team that values innovation, safety, and quality
                  excellence.
                </p> */}
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    How to Apply:
                  </span>{" "}
                  <br />
                  If you're passionate and ready to take your career to the next
                  level, we’d love to hear from you! Submit your application via
                  LinkedIn or email your updated CV.
                </p>
                <br />

                <br />
                <p>
                  Join us in shaping a safer and healthier future through
                  microbiological excellence!
                </p>
              </div>
              {/* </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "50px",
                }}
              >
                <div>
                  <a className="btn" href="mailto:hr@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};
export default Job3;
