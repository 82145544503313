import Header2 from "../../components/Header2/Header2";
import Bg from "../../images/shapes/shape_title_under_line.svg";
import Footer from "../../components/software-company-components/Footer/Footer";
import React, { Fragment, useState } from "react";
import Project from "../../api/project";
import { Link } from "react-router-dom";
import { flushSync } from "react-dom";
import ContactSection from "../../components/software-company-components/ContactSection";
// import bg from "../../images/backgrounds/common-banner.gif";
import bg from "../../images/hero/homebg.png";
import s1 from "../../images/actual/careers/420.png";
import s2 from "../../images/actual/careers/500.png";
import s3 from "../../images/actual/careers/1920.png";
import core1 from "../../images/actual/careers/careers100/1.png";
import core2 from "../../images/actual/careers/careers100/2.png";
import core3 from "../../images/actual/careers/careers100/3.png";
import core4 from "../../images/actual/careers/careers100/4.png";
import benfit1 from "../../images/actual/careers/careers286/1.png";
import benfit2 from "../../images/actual/careers/careers286/2.png";
import benfit3 from "../../images/actual/careers/careers286/3.png";
import benfit4 from "../../images/actual/careers/careers286/4.png";
import cta1 from "../../images/actual/careers/19350.png";

const Careers = () => {
  const [activeFilter, setActiveFilter] = useState("department1");
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <Fragment>
      <Header2 />
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container" style={{ paddingBottom: "200px" }}>
          <div
            className="row"
            style={{
              paddingTop: "25vh",
              paddingBottom: "75px",
            }}
          >
            <div
              className="col-lg-7"
              style={{
                paddingTop: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h1
                  style={{
                    fontSize: "3rem",
                  }}
                >
                  Join a Team That’s
                  <br />
                  Transforming Global
                  <br /> Healthcare
                </h1>
                <br />
                <p
                  style={{
                    fontSize: "1.45rem",
                  }}
                >
                  At saanso , we believe <br /> in innovation, inclusivity, and
                  <br /> empowering our people.
                </p>
              </div>
            </div>
            <div
              className="col-lg-5"
              style={{
                paddingTop: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={s1} alt="" style={{ borderRadius: "15px" }} />
            </div>
          </div>
        </div>
      </div>

      <section style={{ marginTop: "15vh", marginBottom: "75px" }}>
        <h1 style={{ display: "flex", justifyContent: "center" }}>
          Current Openings
        </h1>
        <div className="container" style={{ marginTop: "50px" }}>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "75px",
            }}
          >
            <div className="col-lg-9">
              <h3>
                <a
                  href="/head-of-marketing"
                  style={{
                    borderBottom: "3px solid #3286C7",
                    color: "#3286C7",
                  }}
                >
                  Head Marketing
                </a>
              </h3>
              <h6>
                Position Overview : lead strategic initiatives, boost product
                visibility, and drive sales growth.
                <br /> Qualification : Post Graduation in Marketing - Experience
                : 15+ Years - Work Location : Hyderabad.
              </h6>
            </div>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <a className="btn" href="mailto:hr@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <div className="col-lg-9">
              <h3>
                <a
                  href="/executive-microbiology"
                  style={{
                    borderBottom: "3px solid #3286C7",
                    color: "#3286C7",
                  }}
                >
                  Executive Microbiology
                </a>
              </h3>
              <h6>
                Qualification : B.Pharmacy/ B.Sc., (Life Sciences) - Experience
                : 2-4 Years - Work Location : Denduluru, Eluru.
              </h6>
            </div>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <a className="btn" href="mailto:hr@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <div className="col-lg-9">
              <h3>
                <a
                  href="/technical-assistants-execuitves"
                  style={{
                    borderBottom: "3px solid #3286C7",
                    color: "#3286C7",
                  }}
                >
                  {" "}
                  Technical Assistants / Executives
                </a>
              </h3>
              <h6>
                Department : Production (Sterile Manufacturing) - Qualification
                required for Technical Assistants : ITI/Diploma/Degree in a
                relevant field - Qualification required for Executives :
                B.Pharmacy or B.Sc. in Life Sciences - Experience : 0-4 Years -
                Work Location : Denduluru, Eluru.
              </h6>
            </div>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <a className="btn" href="mailto:hr@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <div className="col-lg-9">
              <h3>
                <a
                  href="/sales-executive"
                  style={{
                    borderBottom: "3px solid #3286C7",
                    color: "#3286C7",
                  }}
                >
                  {" "}
                  Sales Executive - Nutrition
                </a>
              </h3>
              <h6>
                Department : Sales - Qualification : Graduate in any discipline
                (preferably in Science, Pharmacy, or Nutrition)- Experience :
                0-3 Years of sales experience in the pharmaceutical, FMCG, or
                healthcare industry - Work Locations : Vizag, Vijayawada,
                Hyderabad - Gender: Only female candidates are preferred.
              </h6>
            </div>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <a className="btn" href="mailto:hr@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          paddingTop: "75px",
          paddingBottom: "75px",
        }}
      >
        <div className="container">
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "50px",
            }}
          >
            Life at saanso
          </h1>
          <div className="row">
            <div
              className="col-lg-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={s2} alt=" " />
            </div>
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{}}>
                <div>
                  <p style={{ fontSize: "1.15rem" }}>
                    At saanso, you will find a dynamic environment where growth,
                    innovation, and a passion for making a difference fuel
                    everything we do. We offer you the opportunity to work with
                    some of the brightest minds in the industry while impacting
                    millions of lives through affordable, high-quality medicine.
                  </p>
                </div>
                <div>
                  <ul style={{ fontSize: "1.15rem" }}>
                    <li>Collaborative workspaces.</li>
                    <li>Regular team-building events.</li>
                    <li>
                      Opportunities for personal and professional development.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage: `url(${s3})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h1
          style={{
            paddingTop: "75px",
            paddingBottom: "50px",
            paddingLeft: "10px",
            paddingRight: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Our Culture: Empowering People, Driving Innovation
        </h1>
        <div className="container">
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "75px",
            }}
          >
            <div className="col-lg-8">
              <div>
                <p style={{ fontSize: "1.25rem" }}>
                  At saanso , we nurture a culture of inclusivity and growth. We
                  encourage curiosity, foster creativity, and drive continuous
                  improvement. Our open-door policy and emphasis on teamwork
                  ensure that every voice is heard, and every idea is valued.
                </p>
              </div>
              <br />
              <div>
                <ul style={{ fontSize: "1.25rem" }}>
                  <li>
                    Our teams constantly push the boundaries of what's possible
                    in healthcare.
                  </li>
                  <li>Connect with colleagues to solve complex challenges.</li>
                  <li>
                    We believe that diverse perspectives make us stronger.
                  </li>
                  <li>
                    Our people grow through continuous learning, mentorship, and
                    opportunities to lead innovative healthcare.
                  </li>
                  <li>
                    Work-life balance matters to us. we foster a flexible
                    environment where personal well-being and professional
                    growth thrive together.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ paddingTop: "85px", paddingBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "2.5rem",
                paddingBottom: "10px",
              }}
            >
              Our Core Values: The Pillars of saanso
            </h1>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              Our core values are the guiding principles that shape how we
              operate, make decisions, and support our employees.
            </p>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "35px",
                }}
              >
                <img src={core1} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "20px",
                }}
              >
                <p style={{ color: "black", fontSize: "1.85rem" }}>Integrity</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "35px",
                }}
              >
                <img src={core2} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "20px",
                }}
              >
                <p style={{ color: "black", fontSize: "1.85rem" }}>
                  Innovation
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "35px",
                }}
              >
                <img src={core3} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "20px",
                }}
              >
                <p style={{ color: "black", fontSize: "1.85rem" }}>
                  Excellence
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "35px",
                }}
              >
                <img src={core4} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "20px",
                }}
              >
                <p style={{ color: "black", fontSize: "1.85rem" }}>
                  Collaboration
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ paddingTop: "75px", paddingBottom: "75px" }}>
        <div className="container">
          <div className="row">
            <h1
              style={{
                fontSize: "2rem",
                display: "flex",
                justifyContent: "center",
                paddingBottom: "30px",
              }}
            >
              Employee Benefits: Elevating Your Experience
            </h1>

            <p
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "15px",
              }}
            >
              We believe in investing in our employees by offering benefits that
              support their health, growth, and happiness. Here’s what you can
              look forward to when you join us.
            </p>
            <div
              className="col-lg-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  width: "286px",
                  height: "180",
                  backgroundColor: "black",
                  borderRadius: "15px",
                }}
              >
                <img
                  src={benfit1}
                  alt=""
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                />
                <h6
                  style={{
                    paddingTop: "35px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    color: "white",
                  }}
                >
                  Encouraging Young Talent
                </h6>
                <p
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingBottom: "20px",
                    color: "white",
                  }}
                >
                  At saanso, you’ll have the opportunity to collaborate,
                  innovate, and grow in a vibrant work environment with a young
                  and passionate team, guided by experienced industry veterans.
                  {/* dedicated to nurturing your growth and potential. */}
                </p>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  width: "286px",
                  height: "180",
                  backgroundColor: "black",
                  borderRadius: "15px",
                }}
              >
                <img
                  src={benfit2}
                  alt=""
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                />
                <h6
                  style={{
                    paddingTop: "35px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    color: "white",
                  }}
                >
                  Learning and Development
                </h6>
                <p
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingBottom: "20px",
                    color: "white",
                  }}
                >
                  Continuous growth is essential at saanso. We provide access to
                  world-class training programs and mentorship to help you reach
                  your full potential.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  width: "286px",
                  height: "180",
                  backgroundColor: "black",
                  borderRadius: "15px",
                }}
              >
                <img
                  src={benfit3}
                  alt=""
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                />
                <h6
                  style={{
                    paddingTop: "35px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    color: "white",
                  }}
                >
                  Work-Life Balance
                </h6>
                <p
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingBottom: "20px",
                    color: "white",
                  }}
                >
                  We understand the importance of balance. With generous paid
                  time off, we ensure you have the space to thrive both in your
                  personal and professional life.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  width: "286px",
                  height: "180",
                  backgroundColor: "black",
                  borderRadius: "15px",
                }}
              >
                <img
                  src={benfit4}
                  alt=""
                  style={{
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                />
                <h6
                  style={{
                    paddingTop: "35px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    color: "white",
                  }}
                >
                  Career Growth Opportunities
                </h6>
                <p
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    paddingBottom: "20px",
                    color: "white",
                  }}
                >
                  Your career is in your hands With leadership tracks, internal
                  mobility across global offices, and clear promotion paths, we
                  empower your journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          paddingTop: "75px",
          paddingBottom: "75px",
          backgroundImage: `url(${cta1})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 style={{ display: "flex", justifyContent: "center" }}>
                Ready to Make a Difference?
              </h1>
              <br />
              <p style={{ display: "flex", justifyContent: "center" }}>
                Join us at saanso and be part of a mission to make healthcare
                affordable and accessible for all.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "50px",
                }}
              >
                <div>
                  <a className="btn" href="mailto:info@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Fragment>
  );
};
export default Careers;
