import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import React, { Fragment, useState } from "react";

const Job2 = () => {
  return (
    <Fragment>
      <Header2 />

      <section style={{ marginTop: "150px", marginBottom: "75px" }}>
        <div className="container" style={{ marginTop: "50px" }}>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="col-lg-9"
                style={{
                  width: "99vw",
                  marginLeft: "12px",
                  marginRight: "12px",
                  border: "1px solid black",
                  paddingLeft: "3vw",
                  paddingRight: "3vw",
                  paddingTop: "50px",
                  paddingBottom: "75px",
                }}
              >
                {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
                <div>
                  <p>
                    <span
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                      }}
                    >
                      Position :
                    </span>{" "}
                    Technical Assistants / Executives
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      Department :
                    </span>{" "}
                    Production (Sterile Manufacturing)
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      Experience :
                    </span>{" "}
                    0-4 Years
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      Work Location :
                    </span>{" "}
                    Denduluru, Eluru.
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      About the Role:
                    </span>{" "}
                    <br />
                    We are looking for dedicated Technical Assistants and
                    Executives to join our Sterile Manufacturing team. The
                    successful candidates will be instrumental in operating
                    critical manufacturing equipment and ensuring the production
                    of high-quality sterile products.
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      Key Responsibilities:
                    </span>{" "}
                    <br />
                    Operate and maintain sterile manufacturing equipment
                    including Vial Washing Machines, Depyrogenation Tunnels,
                    Vial and Ampoule Filling & Sealing Machines, BFS (Blow Fill
                    Seal) Machines, and Autoclaves.
                    <br />
                    Perform routine checks and maintenance on equipment to
                    ensure optimal performance.
                    <br />
                    Adhere to all safety and quality regulations and procedures.
                    <br />
                    Document operations and production outcomes accurately and
                    consistently.
                    <br />
                    Participate in troubleshooting and resolving technical
                    issues in the production line.
                    <br />
                    Collaborate with quality assurance teams to uphold product
                    standards and comply with industry regulations.
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      Who We're Looking For:
                    </span>{" "}
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Technical Assistants:
                    </span>
                    <br />
                    Qualification: ITI/Diploma/Degree in a relevant field.
                    <br />
                    Experience: 0 to 4 years of hands-on experience in operating
                    manufacturing equipment in a sterile environment.
                    <br />
                    <span style={{ fontWeight: "bold" }}>Executives:</span>
                    <br />
                    Qualification: B.Pharmacy or B.Sc. in Life Sciences.
                    <br />
                    Experience: 0 to 4 years of experience in sterile
                    manufacturing settings, with a strong understanding of GMP
                    practices.
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      Why Join Us?
                    </span>{" "}
                    <br />
                    Opportunity to work in a dynamic and growth-oriented
                    environment.
                    <br />
                    Competitive compensation and benefits package. <br />
                    Be part of a team that values innovation, safety, and
                    quality excellence.
                  </p>
                  <p>
                    <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                      How to Apply:
                    </span>{" "}
                    <br />
                    Interested candidates are encouraged to submit application
                    via LinkedIn or email your updated CV.
                  </p>
                  <br />
                  <p>
                    Join us in shaping a safer and healthier future through
                    microbiological excellence!
                  </p>
                </div>
                {/* </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "50px",
                  }}
                >
                  <div>
                    <a className="btn" href="mailto:hr@saansopharma.in">
                      <span className="btn_label" data-text="SEND YOUR RESUME">
                        Send Your Resume
                      </span>
                      <span className="btn_icon">
                        <i className="fa-solid fa-arrow-up-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};
export default Job2;
