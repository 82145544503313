import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import React, { Fragment, useState } from "react";

const Job4 = () => {
  return (
    <Fragment>
      <Header2 />

      <section style={{ marginTop: "150px", marginBottom: "75px" }}>
        <div className="container" style={{ marginTop: "50px" }}>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="col-lg-9"
              style={{
                width: "99vw",
                marginLeft: "12px",
                marginRight: "12px",
                border: "1px solid black",
                paddingLeft: "3vw",
                paddingRight: "3vw",
                paddingTop: "50px",
                paddingBottom: "75px",
              }}
            >
              {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
              <div>
                <p>
                  <span
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    Position :
                  </span>{" "}
                  Sales Executive - Nutrition
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Qualification :
                  </span>{" "}
                  Graduate in any discipline (preferably in Science, Pharmacy,
                  or Nutrition).
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Gender :
                  </span>{" "}
                  Only female candidates are preferred.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Experience :
                  </span>{" "}
                  0 to 3 years of sales experience in the pharmaceutical, FMCG,
                  or healthcare industry (freshers are welcome).
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Work Location :
                  </span>{" "}
                  Vizag, Vijayawada, Hyderabad.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    About the Role:
                  </span>{" "}
                  <br />
                  The role focuses on driving sales, building strong
                  relationships with healthcare professionals, and ensuring the
                  successful promotion and distribution of our nutrition
                  products.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Key Responsibilities:
                  </span>{" "}
                  <br />
                  <br />
                  1. Sales Achievement <br />
                  2. Customer Relationship Management <br />
                  3. Product Promotion <br />
                  4. Market Intelligence <br />
                  5. Reporting & Documentation
                  <br />
                </p>
                {/* <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Key Performance Indicators:
                  </span>{" "}
                  <br />
                  1.Growth in market share and sales revenue.
                  <br /> 2.Successful execution of product campaigns and
                  maximization of ROI on promotional inputs. <br />
                  3.Timely and effective implementation of marketing strategies
                  and product launches. <br />
                  4.Accurate tracking and reporting of input utilization and
                  brand performance metrics. <br />
                  5.Maintenance and expansion of a high-quality customer
                  database.
                </p> */}
                {/* <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Who We're Looking For:
                  </span>{" "}
                  <br />
                  Qualification: MSc in Microbiology (or equivalent).
                  <br />
                  Experience: 2-4 years of hands-on experience in
                  microbiological testing and analysis. Strong understanding of
                  microbiological techniques and laboratory safety protocols.
                  Detail-oriented with excellent organizational and
                  problem-solving skills. Proficiency in using laboratory
                  equipment and software for data management.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Why Join Us?
                  </span>{" "}
                  <br />
                  Opportunity to work in a dynamic and growth-oriented
                  environment.
                  <br />
                  Competitive compensation and benefits package. <br />
                  Be part of a team that values innovation, safety, and quality
                  excellence.
                </p> */}
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    How to Apply:
                  </span>{" "}
                  <br />
                  If you're passionate and ready to take your career to the next
                  level, we’d love to hear from you! Submit your application via
                  LinkedIn or email your updated CV to{" "}
                  <a href="mailto:hr@saansopharmsa.in">hr@saansopharmsa.in</a>.
                </p>
                <br />
              </div>
              {/* </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "50px",
                }}
              >
                <div>
                  <a className="btn" href="mailto:hr@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};
export default Job4;
