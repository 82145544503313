import Header2 from "../../components/Header2/Header2";
import Footer from "../../components/software-company-components/Footer/Footer";
import React, { Fragment, useState } from "react";

const Job1 = () => {
  return (
    <Fragment>
      <Header2 />

      <section style={{ marginTop: "150px", marginBottom: "75px" }}>
        <div className="container" style={{ marginTop: "50px" }}>
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="col-lg-9"
              style={{
                width: "99vw",
                marginLeft: "12px",
                marginRight: "12px",
                border: "1px solid black",
                paddingLeft: "3vw",
                paddingRight: "3vw",
                paddingTop: "50px",
                paddingBottom: "75px",
              }}
            >
              {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
              <div>
                <p>
                  <span
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    }}
                  >
                    Position :
                  </span>{" "}
                  Executive Microbiology
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Qualification :
                  </span>{" "}
                  Msc Microbiology
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Experience :
                  </span>{" "}
                  2-4 Years
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Work Location :
                  </span>{" "}
                  Denduluru, Eluru.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    About the Role:
                  </span>{" "}
                  <br />
                  We are seeking a skilled and motivated Executive -
                  Microbiology to join our team in Denduluru, Eluru. The ideal
                  candidate will have a strong background in microbiological
                  testing and analysis, with 2-4 years of relevant experience.
                  This role is critical for ensuring the quality and safety
                  standards of our processes and products.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Key Responsibilities:
                  </span>{" "}
                  <br />
                  Conduct microbiological tests and analyses with precision and
                  accuracy. Maintain laboratory equipment, ensuring proper
                  calibration and functionality. Adhere to strict safety
                  protocols and maintain a clean and organized laboratory
                  environment.
                  <br />
                  Record, analyze, and interpret data to ensure compliance with
                  industry standards. Assist in the development and validation
                  of microbiological testing procedures. Collaborate with
                  cross-functional teams to support quality assurance and
                  process improvement initiatives.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Who We're Looking For:
                  </span>{" "}
                  <br />
                  Qualification: MSc in Microbiology (or equivalent).
                  <br />
                  Experience: 2-4 years of hands-on experience in
                  microbiological testing and analysis. Strong understanding of
                  microbiological techniques and laboratory safety protocols.
                  Detail-oriented with excellent organizational and
                  problem-solving skills. Proficiency in using laboratory
                  equipment and software for data management.
                  <br />
                  <br />
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    Why Join Us?
                  </span>{" "}
                  <br />
                  Opportunity to work in a dynamic and growth-oriented
                  environment.
                  <br />
                  Competitive compensation and benefits package. <br />
                  Be part of a team that values innovation, safety, and quality
                  excellence.
                </p>
                <p>
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                    How to Apply:
                  </span>{" "}
                  <br />
                  If you're passionate about microbiology and ready to take your
                  career to the next level, we’d love to hear from you! Submit
                  your application via LinkedIn or email your updated CV.
                </p>
                <br />
                <p>
                  Join us in shaping a safer and healthier future through
                  microbiological excellence!
                </p>
              </div>
              {/* </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "50px",
                }}
              >
                <div>
                  <a className="btn" href="mailto:hr@saansopharma.in">
                    <span className="btn_label" data-text="SEND YOUR RESUME">
                      Send Your Resume
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};
export default Job1;
