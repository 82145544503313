import React, { Fragment } from "react";
import Header2 from "../../components/Header2/Header2";
import Hero2 from "../../components/hero2/hero2";
import FeaturePartners from "../../components/software-company-components/FeaturePartners";
// import About from "../../components/software-company-components/about/about";
import Scrollbar from "../../components/scrollbar/scrollbar";
// import BlogSection from "../../components/software-company-components/BlogSection/BlogSection";
import Footer from "../../components/software-company-components/Footer/Footer";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import { Link } from "react-router-dom";
// import Bg3 from "../../images/shapes/shape_title_under_line.svg";
// import Testimonial from "../../components/software-company-components/Testimonial/Testimonial";
import pImg1 from "../../images/actual/home/section-4/1.png";
import pImg2 from "../../images/actual/home/section-4/2.png";
import pImg3 from "../../images/actual/home/section-4/3.png";
import pImg4 from "../../images/actual/home/section-4/4.png";
import homeabout from "../../images/actual/home/homeabout.png";

const HomePage2 = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const research = [
    {
      Id: "1",
      pImg: pImg1,
      title: "Research & Development",
      slug: "Research and Development",
      sub: "",
      Industry: "",
      Country: "",

      description:
        " Saanso has one of the best R&D infrastructures, combining Development Excellence and manufacturing science, which form the backbone of its manufacturing operations. The Research and Development team possesses wide ranging expertise in Formulation development, Analytical Research and Niche segment development. Our comprehensive and integrated development services are equipped with the best technology to augment its growth path to develop highest quality formulations.",
    },
    {
      Id: "2",
      pImg: pImg2,
      title: "Inhalation Anesthesia",
      slug: "Inhalation Anesthesia",
      sub: "",
      Industry: "",
      Country: "",

      description:
        "Saanso specializes in Inhalation Anesthesia, offering high-quality solutions for safe and effective anesthesia management. Our product range includes key agents such as Isoflurane and Sevoflurane, designed to ensure precise control and rapid patient recovery. Backed by advanced R&D and stringent manufacturing standards, Saanso’s inhalation anaesthetics provide reliable, cost-effective solutions for healthcare professionals, ensuring optimal patient care in both surgical and critical care settings.",
    },
    {
      Id: "3",
      pImg: pImg3,
      title: "Contrast Media",
      slug: "Contrast Media",
      sub: "",
      Industry: "",
      Country: "",

      description:
        "A comprehensive Range of contrast media designed to enhance diagnostic imaging accuracy in various medical fields. Our contrast agents are formulated for optimal safety and effectiveness in procedures such as CT scans, MRIs, and X-rays. With a focus on innovation and quality, Saanso ensures precise visualization for better diagnosis, contributing to improved patient care and outcomes across both diagnostic and interventional settings.",
    },
    {
      Id: "4",
      pImg: pImg4,
      title: "Now launched Critical Care Division",
      slug: "Critical Care",
      sub: "",
      Industry: "",
      Country: "",

      description:
        "We’ve launched our Critical Care Division, dedicated to providing advanced, life-saving healthcare solutions. our expert team is ready to support with injectable drugs meant for life-saving needs.",
    },
  ];

  return (
    <Fragment>
      <div>
        <Header2 />
        <main className="page_content">
          <Hero2 />
          <FeaturePartners />

          {/* <About /> */}
          <section
            style={{
              backgroundColor: "black",
              paddingTop: "60px",
              paddingBottom: "60px",
            }}
          >
            <div className="container">
              <div className="row ">
                <div className="col-lg-8">
                  <h1
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "30px",
                      color: "white",
                    }}
                  >
                    About Us
                  </h1>
                </div>
                <div
                  className="col-lg-4"
                  style={{
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    borderBottom: "1px solid black",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    Saanso, founded in 2017, is a South Indian pharma company
                    offering affordable generics in critical care and specialty
                    areas.
                  </p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <img src={homeabout} alt="" />
                </div>
                <div
                  className="col-lg-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "300px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "15px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        <div>
                          <h2
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              paddingTop: "15px",
                            }}
                          >
                            8+
                          </h2>
                          <p>Operational States</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        <div>
                          <h2
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            10+
                          </h2>
                          <p>Therapy areas</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                        }}
                      >
                        <div>
                          <h2
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            250+
                          </h2>
                          <p>Employees</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ServiceSection />
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="pt-170">
                <div className="heading_block text-center text-white">
                  {/* <div
                    className="heading_focus_text has_underline d-inline-flex"
                    style={{ backgroundImage: `url(${Bg3})`, color: "#3286C7" }}
                  >
                    Research Laboratory
                  </div> */}
                  <h2 className="heading_text mb-0">
                    <mark>Road Map to Therapeutics</mark>
                  </h2>
                </div>

                <div className="case_studies_wrapper">
                  {research.map((project, prj) => (
                    <div className="case_study_block" key={prj}>
                      <div
                        className="case_study_image"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={project.pImg}
                          alt=""
                          style={{ display: "block" }}
                        />
                        {/* {project.slug === "Critical Care" ? (
                          <p
                            style={{
                              position: "absolute",
                              top: "0.1px",
                              right: "0.1px",
                              padding: "10px",
                              backgroundColor: "#3286C7",
                              color: "white",
                              borderTopLeftRadius: "15px",
                            }}
                          >
                            Upcoming
                          </p>
                        ) : (
                          ""
                        )} */}
                      </div>

                      <div className="case_study_content">
                        <ul className="category_list unordered_list text-uppercase">
                          <li>
                            <p>{project.sub}</p>
                          </li>
                        </ul>
                        <h3 className="case_title">{project.title}</h3>
                        <p>{project.description}</p>
                        {/* <ul className="icon_list unordered_list">
                          <li>
                            <span className="icon_list_text">
                              <strong className="text-dark">Industry:</strong>{" "}
                              {project.Industry}
                            </span>
                          </li>
                          <li>
                            <span className="icon_list_text">
                              <strong className="text-dark">Country:</strong>{" "}
                              {project.Country}
                            </span>
                          </li>
                        </ul> */}
                        {/* <ul
                          className="case_technologies unordered_list"
                          data-text="Core Technologies:"
                        >
                          <li>
                            <img src={project.Technologies1} alt="Angular" />
                          </li>
                          <li>
                            <img src={project.Technologies2} alt="Elephent" />
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  ))}
                </div>

                {/* <div className="btns_group pb-50">
                  <Link
                    onClick={ClickHandler}
                    to="/portfolio"
                    className="btn btn-primary"
                  >
                    <span
                      className="btn_label"
                      data-text="View More Cases Study"
                    >
                      View More Cases Study
                    </span>
                    <span className="btn_icon">
                      <i className="fa-solid fa-arrow-up-right"></i>
                    </span>
                  </Link>
                </div> */}
              </div>
            </div>
            {/* <div className="decoration_item shape_image_1">
              <img src={shape1} alt="Techco Shape" />
            </div> */}
          </div>

          {/* <div className="container">
            <Testimonial />
          </div> */}
          <div style={{ paddingTop: "50px", paddingBottom: "50px" }}></div>

          {/* <BlogSection /> */}
        </main>
        <Footer />
        <Scrollbar />
      </div>
    </Fragment>
  );
};
export default HomePage2;
